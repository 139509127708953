import React, { useState, useEffect } from 'react'
import styles from '../Category.module.scss'
import DisplayStyle from '../DisplayMode.module.scss'
import ImageComponent from '../../ImageComponent/SingleImageComponent'
import AddToWishlist from '../../AddToWishlist/AddToWishlist'
import RatingStars from '../../RatingStars/RatingStars'
import ProductPrice from '../../ProductPrice/ProductPrice'
import Button from '@material-ui/core/Button'
import { useSelector } from 'react-redux'
import ClassNames from 'classnames/bind'
import { MAGENTO_URL, MICROSERVICE_WISHLIST } from '../../../../userglobals'
import axios from 'axios'
import { Link } from 'gatsby'
import {
   addToWishlist,
   removeFromWishlist
} from '../../../store/actions'

let cx = ClassNames.bind(DisplayStyle);

type productCardType = {
   product: any
   displayMode: string
   location?: Location
}

const productCard: React.FC<productCardType> = ({ product, displayMode, location }) => {
   const wishlist = useSelector((state: any) => state.wishlist);
   const userAuth = useSelector((state: any) => state.userAuth);

   const [checked, setChecked] = useState(false);

   useEffect(() => {
      const alreadyChecked = wishlist?.some((x: any) => x?.product_id === product.id.toString());
      const alreadyChecked2 = wishlist?.some((x: any) => x === product.id);

      if (alreadyChecked || alreadyChecked2) {
         setChecked(true)
      }
   }, [wishlist])

   const ClassNameImgDesk = cx({
      imgLista: displayMode === 'lista',
   });

   const ClassNameBodyDesk = cx({
      cardBodyGaleria: displayMode === 'galeria',
      cardBodyLista: displayMode === 'lista',
      cardBodyShowcase: displayMode === 'showcase'
   });

   const ClassNameButton = cx({
      divButtonCardGaleria: displayMode === 'galeria' || displayMode === 'showcase',
      divButtonCardLista: displayMode === 'lista'
   });

   const toggleCheckbox = () => {
      if (!checked) {
         setChecked(true)
         addToWishlist(product.id)
         if (userAuth.auth === true) {
            axios.post(MICROSERVICE_WISHLIST + "add-wishlist", { ...userAuth, productId: product.id })
            // .then(res=>console.log(res.data))
         }
      } else {
         setChecked(false)
         removeFromWishlist(product.id)
         if (userAuth.auth === true) {
            axios.post(MICROSERVICE_WISHLIST + "delete-wishlist", { ...userAuth, productId: product.id })
            // .then(res=>console.log(res.data))
         }
      }
   };

   return (
      <>
         <Link to={`/${product.slug}/`}>
            <div className={ClassNameImgDesk} style={{ minHeight: '235px' }}>
               {
                  product.custom_attributes ?
                     <ImageComponent
                        imgUrl={MAGENTO_URL + "media/catalog/product" + product.custom_attributes.find((x: any) => x.attribute_code === "image")!.value + "?width=235&auto=webp"}
                        altImg={product.name}
                        heightContainer={235}
                     /> :
                     <ImageComponent
                        imgUrl={MAGENTO_URL + "media/catalog/product" + product.image + "?width=235&auto=webp"}
                        altImg={product.name}
                        heightContainer={235}
                     />
               }
            </div>
         </Link>
         <div className={ClassNameBodyDesk} style={{ minHeight: '192px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex' }}>
               <div style={{ width: '90%', lineHeight: '15px', paddingRight: '3px' }}>
                  <Link to={`/${product.slug}/`} style={{ textDecoration: 'none', color: '#595959' }}>
                     <span style={{ fontSize: '12px' }}>{product.name}</span> <br />
                     <span style={{ fontSize: '10px' }}>CÓD.: {product.sku}</span>
                  </Link>
               </div>
               <div style={{ width: '10%', display: 'flex', justifyContent: 'center' }}>
                  <AddToWishlist checked={checked} toggleCheckbox={toggleCheckbox} />
               </div>
            </div>

            <div style={{ maxHeight: '50px' }}>
               <RatingStars />
            </div>

            <div>
               <ProductPrice product={product} location={location} />
            </div>

            <div className={ClassNameButton}>
               <Button
                  disableTouchRipple
                  className={styles.buttonBuy}
                  component={Link}
                  to={`/${product.slug}/`}
                  variant="contained"
               >
                  Comprar
               </Button>
            </div>
         </div>
      </>
   )
}

export default productCard