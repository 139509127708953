import React, { useState, useEffect } from 'react'
import styles from './Search.module.scss'
import ProductCard from '../Category/components/productCard'
import ClassNames from 'classnames/bind'

let cx = ClassNames.bind(styles);

type SearchType = {
  location: any
}

const Search: React.FC<SearchType> = ({ location }) => {
  const [products, setProducts] = useState([]);
  useEffect(() => {
    if (location !== undefined && location !== null && location.state !== null && location.state !== undefined && 'products' in location.state) {
      setProducts(location.state.products)
    }
  }, [location.state]);
  
  let idx = 0;
  return (
    <div>
      <div style={{ backgroundColor: '#fff', padding: '15px', borderBottom: '3px solid #f8f7f6', fontSize: '16px' }}>
        Resultados de busca para "<strong>{location?.state?.searchInput}</strong>"
      </div>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {products.map((product, index) => {
          idx = idx + 1;
          const ClassName = cx({
            cardGaleria: idx % 5 !== 0,
            lastCardGaleria: idx % 5 === 0,
          });
          return (
            <div key={index} className={ClassName}>
              <ProductCard product={product} displayMode={'galeria'} location={location} />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Search
